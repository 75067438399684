/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BSC,
  useEthers,
  useToken,
  useTokenBalance,
  useEtherBalance,
  useContractCalls,
  useGasPrice,
  useSendTransaction,
  useContractFunction
} from '@usedapp/core'

import { css } from '@emotion/react'
import { RingLoader } from 'react-spinners'

import { BigNumber, formatFixed, parseFixed } from '@ethersproject/bignumber'
import {
  formatEther,
  formatUnits,
  parseEther,
  parseUnits
} from '@ethersproject/units'
import { Interface } from '@ethersproject/abi'
import { Contract } from '@ethersproject/contracts'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import {
  DisconnectButton,
  InstallMetaMaskButton,
  MetaMaskButton,
  ToBSCButton,
  WatchYETIButton,
  WalletConnectButton,
  ToggleButton,
  YETIBalance
} from './dapp'

import { sendYetiReceipt } from '../requests'

// ethers.utils.isAddress('0xaddress')

// import '../styles/presale.css'
// import '../styles/components.css'

import yetiABIJSON from '../assets/yetiABI'
import pancakeABIJSON from '../assets/pancakeABI'

import warningImg from '../assets/warning.svg'

const yetiABI = new Interface(yetiABIJSON)
const pancakeABI = new Interface(pancakeABIJSON)
const pancakeYETIwBNBContract = '0xff84a2ce052e205b909469dac8effdc0cb664a4e'
const pancakewBNBUSDTContract = '0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae'

const yetiContractAddress = '0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9'
const yetiContract = new Contract(yetiContractAddress, yetiABI)
// const yetiContract = new Contract(yetiContractAddress, yetiABI)
// const receiveAddress = '0xf8394C2Ad7D009170dBb24C591193e0712F287b7'
const receiveAddress = '0x423E9ae12Fb22F635c21e0BE70f32D1586a93e78' // YETI BACK

const RPC_URLS = {
  1: 'https://mainnet.infura.io/v3/10dc06d1ed0446a7aabb9dc86df0983d',
  56: 'https://bsc-dataseed.binance.org/'
}

const walletConnect = new WalletConnectConnector({
  chainId: 56,
  rpc: { 1: RPC_URLS[1], 56: RPC_URLS[56] },
  bridge: 'https://bridge.walletconnect.org',
  // pollingInterval: POLLING_INTERVAL,
  qrcode: true
})

const wcError = 'Unavailable with WalletConnect!'

export default function Yeti (props) {
  const {
    plans,
    selectedPlanID,
    selectedPlan,
    purchaseMonths,
    account: userAccount,
    token
  } = props
  // let selectedPlan = plans.common.find(plan => plan.id === selectedPlanID)

  const [installed, setInstalled] = useState(false)
  const [connectorName, setConnectorName] = useState('')

  const [hideButtons, setHideButtons] = useState(false)
  const [walletAddress, setWalletAddress] = useState(null)
  const [walletSignature, setWalletSignature] = useState(null)
  const [allowAutoHide, setAllowAutoHide] = useState(true)

  const ethers = useEthers()
  const { account, chainId, library, error } = ethers

  useEffect(() => {
    setWalletSignature(null)
  }, [account])

  useEffect(() => {
    if (window.ethereum) {
      setInstalled(true)
    }
  }, [])

  const etherBalance = useEtherBalance(account)
  const etherBalanceFormated = etherBalance ? formatEther(etherBalance) : 0

  const { BNB, YETI, priceBNB, priceYETI } = PancakeRates({
    selectedPlan,
    purchaseMonths
  })

  const offerPriceYETI = priceYETI * 0.6

  return (
    <>
      {/* <DisconnectButton ethers={ethers} connectorName={connectorName} /> */}

      <Controls
        installed={installed}
        setAllowAutoHide={setAllowAutoHide}
        ethers={ethers}
        setConnectorName={setConnectorName}
      />

      {/* <ToggleButton
        hideButtons={hideButtons}
        setHideButtons={setHideButtons}
        setAllowAutoHide={setAllowAutoHide}
        ethers={ethers}
      /> */}

      {/* <PayBalance fromSymbol={'BNB'} balance={etherBalanceFormated} />
      <YETIBalance /> */}

      {/* <DAppInfo ethers={ethers} /> */}

      <Web3Pay
        selectedPlan={selectedPlan}
        purchaseMonths={purchaseMonths}
        priceYETI={offerPriceYETI}
        priceBNB={priceBNB}
        token={token}
        userAccount={userAccount}
        connectorName={connectorName}
        walletSignature={walletSignature}
        setWalletSignature={setWalletSignature}
      />

      {/* <PancakeInfo
        selectedPlan={selectedPlan}
        purchaseMonths={purchaseMonths}
        userAccount={userAccount}
        walletSignature={walletSignature}
        setWalletSignature={setWalletSignature}
        token={token}
      /> */}
    </>
  )
}

function Controls (props) {
  const { installed, ethers, setAllowAutoHide, setConnectorName } = props
  const { account, chainId, library, error } = ethers
  if (account && chainId === BSC.chainId) return null

  return (
    <>
      <div className='col-12 md:col-12 lg:col-4'>
        <InstallMetaMaskButton
          installed={installed}
          ethers={ethers}
          error={error}
        />
        <MetaMaskButton
          installed={installed}
          setAllowAutoHide={setAllowAutoHide}
          ethers={ethers}
          setConnectorName={setConnectorName}
        />
        <ToBSCButton ethers={ethers} />
      </div>
      <div className='col-12 md:col-12 lg:col-4'>
        <WalletConnectButton
          setAllowAutoHide={setAllowAutoHide}
          ethers={ethers}
          setConnectorName={setConnectorName}
          walletConnect={walletConnect}
        />
      </div>
    </>
  )
}

export function PancakeRates (props) {
  const { selectedPlan, purchaseMonths } = props

  let priceUSD = selectedPlan?.priceUSD || 1

  if (selectedPlan?.id === 'custom') {
    let savePercent = 0
    for (let i = 0; i < purchaseMonths; i++) {
      savePercent += selectedPlan.monthSave[i]
    }
    const resultPrice =
      (selectedPlan.priceMonthUSD * purchaseMonths * (100 - savePercent)) / 100

    priceUSD = resultPrice
  }

  const calls = useContractCalls([
    {
      abi: pancakeABI,
      address: pancakeYETIwBNBContract,
      method: 'getReserves',
      args: []
    },
    {
      abi: pancakeABI,
      address: pancakewBNBUSDTContract,
      method: 'getReserves',
      args: []
    }
  ])
  // console.log('PancakeInfo call', calls)

  // YETI / WBNB
  const c0r0 = calls && calls[0]?._reserve0
  const reserveYETI = c0r0 ? formatUnits(c0r0, 9) : 0
  const c0r1 = calls && calls[0]?._reserve1
  const reserveWBNB = c0r1 ? formatUnits(c0r1, 18) : 0

  const constantProduct = reserveYETI * reserveWBNB
  //  WBNB / USDT
  const c1r0 = calls && calls[1]?._reserve0
  const usdtR = c1r0 ? formatUnits(c1r0, 18) : 0
  const c1r1 = calls && calls[1]?._reserve1
  const wbnbR = c1r1 ? formatUnits(c1r1, 18) : 0

  const priceBNB = priceUSD * (+wbnbR / +usdtR) || 0

  const priceYETI =
    (priceUSD * (+wbnbR / +usdtR) * (+reserveYETI / +reserveWBNB)) / 0.98 || 0

  return {
    YETI: 1 / ((+wbnbR / +usdtR) * (+reserveYETI / +reserveWBNB)),
    BNB: +usdtR / +wbnbR,
    priceBNB,
    priceYETI
  }
}

function PancakeInfo (props) {
  const {
    selectedPlan,
    purchaseMonths,
    token,
    userAccount,
    walletSignature,
    setWalletSignature
  } = props

  const { BNB, YETI, priceBNB, priceYETI } = PancakeRates({
    selectedPlan,
    purchaseMonths
  })

  const offerPriceYETI = priceYETI * 0.6

  return (
    <div className='balance'>
      {/* <div className='balance-info'>PLAN</div> */}
      {/* <div className='balance-info'>
        Price BNB {priceBNB}
      </div>
      <div className='balance-info'>
        Price YETI <del>{priceYETI}</del>
        <span>{offerPriceYETI}</span>
      </div>

      <div className='balance-info'>
        YETI Coin price{' '}
        {YETI}
      </div> */}

      {/* <div className='balance-info'>YETI / wBNB</div> */}
      {/* <div className='balance-info'>YETI Reserve {reserveYETI.toString()}</div> */}
      {/* <div className='balance-info'>wBNB Reserve {reserveWBNB.toString()}</div> */}
      {/* <div className='balance-info'>
        YETI for wBNB {+reserveYETI / +reserveWBNB}
      </div>
      <div className='balance-info'>
        wBNB for YETI {+reserveWBNB / +reserveYETI}
      </div> */}

      <Web3Pay
        selectedPlan={selectedPlan}
        purchaseMonths={purchaseMonths}
        priceYETI={offerPriceYETI}
        priceBNB={priceBNB}
        token={token}
        userAccount={userAccount}
        walletSignature={walletSignature}
        setWalletSignature={setWalletSignature}
      />

      {/* <div className='balance-info'>wBNB / USDT</div> */}
      {/* <div className='balance-info'>wBNB Reserve {wbnbR.toString()}</div> */}
      {/* <div className='balance-info'>USDT Reserve {usdtR.toString()}</div> */}
      {/* <div className='balance-info'>USDT for wBNB {+usdtR / +wbnbR}</div> */}
      {/* <div className='balance-info'>wBNB for USDT {+wbnbR / +usdtR}</div> */}
      {/* <div className='balance-info'>PancakeInfo {JSON.stringify(calls)}</div> */}
    </div>
  )
}

function Web3Pay (props) {
  const {
    selectedPlan,
    purchaseMonths,
    priceYETI,
    priceBNB,
    token,
    userAccount,
    connectorName,
    walletSignature,
    setWalletSignature
  } = props
  const [hidePayNow, setHidePayNow] = useState(false)
  const ethers = useEthers()
  const { account, chainId, library, error } = ethers
  // const yetiInfo = useToken(yetiContractAddress)
  // const yetiBalance = useTokenBalance(yetiContractAddress, account)
  // const yetiBalanceFormated = yetiBalance ? formatUnits(yetiBalance, 9) : 0 // daiInfo?.decimals ||

  // if (state && (state.status === 'Mining TX' || state.status === 'Success'))
  //   return <PaymentStatus state={state} />

  // if (payment.pay_amount > +etherBalanceFormated) return null
  if (!account || chainId !== BSC.chainId) return null

  return (
    <>
      {/* <Web3PayBNB priceBNB={priceBNB} ethers={ethers} /> */}
      <Web3PayYETI
        selectedPlan={selectedPlan}
        purchaseMonths={purchaseMonths}
        priceYETI={priceYETI}
        ethers={ethers}
        token={token}
        userAccount={userAccount}
        walletSignature={walletSignature}
        setWalletSignature={setWalletSignature}
      />
      <Web3PayBNB
        selectedPlan={selectedPlan}
        purchaseMonths={purchaseMonths}
        priceBNB={priceBNB}
        ethers={ethers}
        token={token}
        userAccount={userAccount}
        connectorName={connectorName}
        walletSignature={walletSignature}
        setWalletSignature={setWalletSignature}
      />
    </>
  )
}

function Web3PayYETI (props) {
  const {
    selectedPlan,
    purchaseMonths,
    priceYETI,
    ethers,
    token,
    userAccount,
    walletSignature,
    setWalletSignature
  } = props
  const { account, chainId, library, error } = ethers
  const [buttonText, setButtonText] = useState('Pay with YETI')
  const [balanceError, setBalanceError] = useState('')
  const [showError, setShowError] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  // const gasPrice = useGasPrice()
  const navigate = useNavigate()
  const { state, send, events, resetState } = useContractFunction(
    yetiContract,
    'transfer',
    { transactionName: 'YETI VPN Premium' }
  )
  const yetiBalance = useTokenBalance(yetiContractAddress, account)
  const yetiBalanceFormated = yetiBalance ? +formatUnits(yetiBalance, 9) : 0 // daiInfo?.decimals ||

  useEffect(() => {
    if (yetiBalanceFormated < priceYETI) {
      setBalanceError('Insufficient YETI balance!')
    } else {
      setBalanceError(null)
      setShowError(false)
    }
  }, [priceYETI, yetiBalance])

  useEffect(() => {
    console.log('Web3PayYETI state', state)
    console.log('Web3PayYETI events', events)

    const run = async () => {
      try {
        const result = await sendYetiReceipt({
          coin: 'YETI',
          token,
          subId: selectedPlan.id,
          months: purchaseMonths,
          tx: state.receipt.transactionHash,
          signature: walletSignature
        })
        console.log('sendYetiReceipt result', result)
        if (result.success) {
          setShowLoader(false)
          navigate('/processing')
        }
      } catch (error) {
        console.log('sendYetiReceipt failed', error)
        setButtonText('YETI Purchase Failed')
        setShowLoader(false)
      }
    }

    if (state.status === 'Success') {
      setButtonText('Activating Subscription')
      run()
    } else if (state.status === 'Mining') {
      setButtonText('Awaiting New Block')
    } else if (state.status === 'Fail') {
      setShowLoader(false)
      setButtonText('Pay with YETI')
    } else if (state.status === 'Exception') {
      setShowLoader(false)
      setButtonText('Pay with YETI')
    }
  }, [state, events])

  const onClick = async () => {
    console.log('web3-pay YETI', priceYETI, receiveAddress)
    if (balanceError) {
      setShowError(true)
      return null
    }

    setShowLoader(true)

    try {
      const value = parseUnits((+priceYETI).toFixed(9), 9)

      if (!walletSignature) {
        setButtonText('Awaiting Signature')
        const signer = library.getSigner()
        const signature = await signer.signMessage(
          `Get YETI VPN Premium for account ${userAccount.email}`
        )
        setWalletSignature(signature)
        console.log('signature', signature)
        setButtonText('Confirm Transaction')
        send(receiveAddress, value)
      } else {
        console.log('signature', walletSignature)
        setButtonText('Confirm Transaction')
        send(receiveAddress, value)
      }
    } catch (error) {
      console.log('error', error)
      setButtonText('Pay with YETI')
      setShowLoader(false)
    }
  }

  return (
    <div className='payment-wrapper col-12 md:col-12 lg:col-4'>
      
      <button
        className={`button button--third metamask-buttons web3-pay flex items-center justify-center ${
          balanceError ? 'disabled' : ''
        }`}
        onClick={onClick}
      >
        {buttonText}
        {showLoader ? (
          <div className='loader '>
            <RingLoader
              color='#000000'
              loading
              css={css`
                margin: 0 20px;
              `}
              size={18}
            />
          </div>
        ) : null}
      </button>
      
      <p
        className={
          'yeti-text flex justify-center w-full text-center text-micro leading-normal mt-1 flex leading-none text-red ' +
          (showError && balanceError ? '' : 'hidden')
        }
      >
        <img className='mr-1' src={warningImg} alt='warning' />
        <strong className='self-center'>{balanceError}</strong>
      </p>

      <div className='text-micro font-medium text-grey-dark payment-price'>{priceYETI.toFixed(4)} YETI</div>
    </div>
  )
}

function Web3PayBNB (props) {
  const {
    selectedPlan,
    purchaseMonths,
    priceBNB,
    ethers,
    token,
    userAccount,
    connectorName,
    walletSignature,
    setWalletSignature
  } = props
  const { account, chainId, library, error } = ethers
  const [stateError, setStateError] = useState('')
  const [buttonText, setButtonText] = useState('Pay with BNB')
  const [showError, setShowError] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  // const gasPrice = useGasPrice()
  const navigate = useNavigate()
  const gasPrice = useGasPrice() || 5
  const { sendTransaction, state } = useSendTransaction({
    transactionName: 'YETI VPN Premium'
  })
  const txCost = formatEther(gasPrice * 21000)
  const etherBalance = useEtherBalance(account)
  const etherBalanceFormated = etherBalance ? formatEther(etherBalance) : 0

  const wcDisable = connectorName === 'WalletConnectConnector'

  useEffect(() => {
    if (etherBalanceFormated < priceBNB + txCost) {
      setStateError('Insufficient BNB balance!')
      // console.log('Insufficient BNB balance!', +etherBalanceFormated, priceBNB, +txCost)
    } else {
      setShowError(false)
      setStateError(null)
    }
  }, [priceBNB, etherBalance])

  useEffect(() => {
    console.log('Web3PayBNB state', state)

    const run = async () => {
      try {
        const result = await sendYetiReceipt({
          coin: 'BNB',
          token,
          subId: selectedPlan.id,
          months: purchaseMonths,
          tx: state.receipt.transactionHash,
          signature: walletSignature
        })
        console.log('sendYetiReceipt result', result)
        if (result.success) {
          setShowLoader(false)
          navigate('/processing')
        }
      } catch (error) {
        console.log('sendYetiReceipt failed', error)
        setButtonText('BNB Purchase Failed')
        setShowLoader(false)
      }
    }

    if (state.status === 'Success') {
      setButtonText('Activating Subscription')
      run()
    } else if (state.status === 'Mining') {
      setButtonText('Awaiting New Block')
    } else if (state.status === 'Fail') {
      setShowLoader(false)
      setButtonText('Pay with BNB')
    } else if (state.status === 'Exception') {
      setShowLoader(false)
      setButtonText('Pay with BNB')
    }
  }, [state])

  const onClick = async () => {
    console.log('web3-pay BNB', priceBNB, receiveAddress)
    if (stateError) {
      setShowError(true)
      return null
    }

    if (wcDisable) {
      setShowError(true)
      return null
    } 

    setShowLoader(true)

    try {
      const value = parseEther(priceBNB.toString())

      if (!walletSignature) {
        setButtonText('Awaiting Signature')
        const signer = library.getSigner()
        const signature = await signer.signMessage(
          `Get YETI VPN Premium for account ${userAccount.email}`
        )
        setWalletSignature(signature)
        console.log('signature', signature)
        setButtonText('Confirm Transaction')
        if (connectorName === 'WalletConnectConnector') {
          console.log('WalletConnectConnector', library)
          window.library = library
        } else {
          sendTransaction({
            from: account,
            to: receiveAddress,
            value,
            gasPrice,
            chainId
          })
        }
      } else {
        console.log('signature', walletSignature)
        setButtonText('Confirm Transaction')
        if (connectorName === 'WalletConnectConnector') {
          console.log('WalletConnectConnector', connectorName)
        } else {
          sendTransaction({
            from: account,
            to: receiveAddress,
            value,
            gasPrice,
            chainId
          })
        }
      }
    } catch (error) {
      setButtonText('Pay with BNB')
      setShowLoader(false)
      console.log('error', error)
    }
  }

  return (
    <div className='payment-wrapper col-12 md:col-12 lg:col-4'>
      
      <button
        className={`button button--third metamask-buttons web3-pay flex items-center justify-center ${
          (stateError || wcDisable )? 'disabled' : ''
        }`}
        onClick={onClick}
      >
        {buttonText}
        {showLoader ? (
          <div className='loader '>
            <RingLoader
              color='#000000'
              loading
              css={css`
                margin: 0 20px;
              `}
              size={18}
            />
          </div>
        ) : null}
      </button>
      
      <p
        className={
          'yeti-text flex justify-center w-full text-center text-micro leading-normal mt-1 flex leading-none text-red ' +
          (showError && stateError ? '' : 'hidden')
        }
      >
        <img className='mr-1' src={warningImg} alt='warning' />
        <strong className='self-center'>{wcDisable? wcError: stateError}</strong>
      </p>

      <div className='text-micro font-medium text-grey-dark payment-price'>{priceBNB.toFixed(4)} BNB</div>
    </div>
  )
}

function PaymentStatus (props) {
  const { state } = props
  let stateText = state.status
  if (state.status === 'Payment Sent') {
    stateText = state.status
  }

  return (
    <>
      <div className='payment-row'>
        <span className='payment-pay-now-title'>Pay Now Status: </span>
        <span className='payment-pay-now-text'> {stateText}</span>
      </div>
    </>
  )
}

function PayBalance (props) {
  const { fromSymbol, balance } = props

  return (
    <div className='balance row'>
      <div className='balance-info'>
        Balance: {+(+balance).toFixed(4)} {fromSymbol.toUpperCase()}
      </div>
    </div>
  )
}
