import React from 'react'
import { Link } from 'react-router-dom'
import { Plan, Plans } from '../components/Plans'
import windowsBadge from '../assets/badge-windows-version.png'
import appStoreBadge from '../assets/badge_ios.svg'
import googlePlayBadge from '../assets/google-play-badge.svg'
import { isSubscribedCheck } from '../utils'

export default function AccountInfo (props) {
  const { account, plans } = props
  const isSubscribed = isSubscribedCheck(account)
  let selectedPlan = null
  if (isSubscribed) {
    if (account.paymentInfo.source === 'paddle') {
      selectedPlan =
        plans.common.find(
          plan =>
            plan.providers.paddle.id.toString() ===
            account.paymentInfo.subscription_plan_id
        ) || plans.custom
    } else if (account.paymentInfo.source === 'yeticoin') {
      selectedPlan =
        plans.common.find(plan => plan.id === account.paymentInfo.planId) ||
        plans.custom
    } else if (account.paymentInfo.source === 'paypal') {
      selectedPlan =
        plans.common.find(
          plan => plan.providers.paypal.id === account.paymentInfo.plan_id
        ) || plans.custom
    }
  }

  return (
    <div className='yeti-container container text-black flex-grow flex-shrink-0'>
      <div className='Card2 smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col p-6'>
        <h2 className='yeti-text text-h4 leading-normal font-medium tracking-tight mb-6 text-center'>
          Account
        </h2>
        {isSubscribed ? (
          <Active plans={plans} selectedPlan={selectedPlan} account={account} />
        ) : (
          <NotActive />
        )}
      </div>
      <div className='Card2 smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col p-6'>
        <h5 className='yeti-text text-h5 leading-normal font-medium tracking-tight mb-6 text-center'>
          Download YETI VPN
        </h5>
        <div className='yeti-button'>
          <div className='dl-badges'>
            <a
              className='focus:outline-none select-none ease-out duration-250 cursor-pointer'
              href='https://apps.apple.com/us/app/yeti-vpn/id1544800574'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='inline-flex'
                src={appStoreBadge}
                alt=''
                style={{ height: '72px' }}
              />
            </a>
            <a
              className='dllink mx-7 focus:outline-none select-none ease-out duration-250 cursor-pointer'
              href='https://yetivp.com/dl/windows/direct/'
            >
              <img
                className='inline-flex'
                src={windowsBadge}
                alt=''
                style={{ height: '72px' }}
              />
            </a>
            <a
              className='focus:outline-none select-none ease-out duration-250 cursor-pointer'
              href='https://play.google.com/store/apps/details?id=com.yetivp.vpn'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='inline-flex'
                src={googlePlayBadge}
                alt=''
                style={{ height: '72px' }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
         
function NotActive () {
  return (
    <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
      YETI VPN Premium subscription is not active
    </div>
  )
}

function Active ({ account, plans, selectedPlan }) {
  return (
    <>
      <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
        YETI VPN Premium subscription is active
      </div>
      <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
        Your current plan:
      </div>
      <CurrentSubscriptionPlan
        account={account}
        plans={plans}
        selectedPlan={selectedPlan}
      />
      <Cancel account={account} />
    </>
  )
}

function Cancel ({ account, plans, selectedPlan }) {
  if (account.subStatus.status === 'ACTIVE') {
    if (account.paymentInfo.source === 'paddle') {
      return (
        <div className='mt-6 flex row justify-around'>
          <Link
            className='yeti-button p-3 focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white yeti-button--v-contained yeti-button--c-blue bg-blue border-blue mt-3'
            to='/cancel'
          >
            Cancel Subscription
          </Link>
        </div>
      )
    } else if (account.paymentInfo.source === 'inapp') {
      return null
    }
  } else {
    const nextPaymentTime = account.subStatus.nextPaymentTime
    return (
      <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
        Your subscription is cancelled, but will be active untill{' '}
        {nextPaymentTime}
      </div>
    )
  }
}

function CurrentSubscriptionPlan ({ account, plans, selectedPlan }) {
  if (account.paymentInfo.source === 'paddle') {
    if (plans && selectedPlan) {
      return (
        <>
          <Plans
            plans={plans}
            selectedPlanID={selectedPlan.id}
            setSelectedPlanID={() => {}}
          />
          {/* <div className='yeti-row row justify-center'>
          <Plan
            plan={selectedPlan}
            selectedPlanID={selectedPlan.id}
            setSelectedPlanID={() => {}}
          />
        </div> */}
        </>
      )
    }
  } else if (account.paymentInfo.source === 'inapp') {
    if (account.paymentInfo.os === 'ios') {
      return (
        <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
          iOS subscription
        </div>
      )
    } else if (account.paymentInfo.os === 'android') {
      return (
        <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
          Android subscription
        </div>
      )
    }
  }

  return (
    <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
      Loading awailable subscription plans...
    </div>
  )
}
