import React from 'react'

function SelectDot({ selected }) {
  if (selected) {
    return <SelectDotSelected />
  } else {
    return <SelectDotNotSelected />
  }
}

function SelectDotSelected () {
  return (
    <>
      <div
        className='rounded-full border transition-all duration-250 ease-out relative border-blue bg-blue hidden lg:block mt-1 flex-shrink-0 mr-3'
        style={{ width: 24, height: 24 }}
      >
        <div
          className='absolute m-auto inset-0 bg-white rounded-full transition-opacity duration-250 ease-out opacity-100'
          style={{ width: 8, height: 8 }}
        />
      </div>
      <div
        className='rounded-full border transition-all duration-250 ease-out relative border-blue bg-blue lg:hidden mt-1 flex-shrink-0 mr-2'
        style={{ width: 16, height: 16 }}
      >
        <div
          className='absolute m-auto inset-0 bg-white rounded-full transition-opacity duration-250 ease-out opacity-100'
          style={{ width: 6, height: 6 }}
        />
      </div>
    </>
  )
}

function SelectDotNotSelected () {
  return (
    <>
      <div
        className='rounded-full border transition-all duration-250 ease-out relative border-grey-lighter bg-white hidden lg:block mt-1 flex-shrink-0 mr-3'
        style={{ width: 24, height: 24 }}
      >
        <div
          className='absolute m-auto inset-0 bg-white rounded-full transition-opacity duration-250 ease-out'
          style={{ width: 8, height: 8 }}
        />
      </div>
      <div
        className='rounded-full border transition-all duration-250 ease-out relative border-grey-lighter bg-white lg:hidden mt-1 flex-shrink-0 mr-2'
        style={{ width: 16, height: 16 }}
      >
        <div
          className='absolute m-auto inset-0 bg-white rounded-full transition-opacity duration-250 ease-out'
          style={{ width: 6, height: 6 }}
        />
      </div>
    </>
  )
}

export default SelectDot
