/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel
// } from 'react-accessible-accordion'
import { css } from '@emotion/react'
import { RingLoader } from 'react-spinners'

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css'

// import { PayPal } from '../components/Paypal'
// import plans from '../subPlans'
import AccountInfo from './Account'
import Yeti from '../components/Yeti'
import { Plans, Motivation } from '../components/Plans'
import { CustomPlan } from '../components/CustomPlan'
import { isSubscribedCheck } from '../utils'
// import moneyBackBadge from './assets/money-back-badge-small.svg'

const Paddle = window.Paddle

function Home (props) {
  const {
    paypalClientID,
    selectedPlanID,
    setSelectedPlanID,
    account,
    token,
    plans,
    showPaddleLoader,
    setShowPaddleLoader
  } = props
  const location = useLocation()
  const [purchaseMonths, setPurchaseMonths] = useState(1)
  const isSubscribed = isSubscribedCheck(account)
  console.log('isSubscribed', isSubscribed, account)

  if (!token) {
    return <main className='flex-grow flex-shrink-0 py-10'></main>
  }

  if (!isSubscribed && location.pathname !== '/processing') {
    const selectedPlan =
      plans?.common.find(plan => plan.id === selectedPlanID) || plans?.custom

    return (
      <main className='flex-grow flex-shrink-0 py-10 home'>
        <Motivation />
        {/* <PurchaseSteps /> */}
        <Plans
          showBest
          plans={plans}
          selectedPlanID={selectedPlanID}
          setSelectedPlanID={setSelectedPlanID}
        />
        <CustomPlan
          plans={plans}
          selectedPlanID={selectedPlanID}
          setSelectedPlanID={setSelectedPlanID}
          selectedPlan={selectedPlan}
          purchaseMonths={purchaseMonths}
          setPurchaseMonths={setPurchaseMonths}
        />
        <div className='yeti-container container'>
          <div className='payment-block focus:outline-none px-3 py-3 md:px-4 text-grey-darkest rounded-2 border-2 shadow-1 bg-white border-white transition-all duration-250 ease-out'>
            <PurchaseSteps2 />
            <SubOrLogin
              paypalClientID={paypalClientID}
              plans={plans}
              selectedPlan={selectedPlan}
              selectedPlanID={selectedPlanID}
              account={account}
              token={token}
              purchaseMonths={purchaseMonths}
              showPaddleLoader={showPaddleLoader}
              setShowPaddleLoader={setShowPaddleLoader}
            />
          </div>
        </div>
      </main>
    )
  } else {
    return <AccountInfo account={account} plans={plans} />
  }
}

function PurchaseSteps () {
  return (
    <div className='yeti-container container'>
      <div className='yeti-row row'>
        <div className='yeti-col col'>
          <div className='flex'>
            <span className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-darkest font-medium pb-2 border-b-2 mr-8'>
              1. Choose plan
            </span>
          </div>
          <hr className='border-grey-lighter' />
        </div>
      </div>
    </div>
  )
}

function PurchaseSteps2 () {
  return (
    // <div className='yeti-container container'>
    <div className='yeti-row row'>
      <div className='yeti-col col'>
        <div className='flex'>
          <span className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey hover:text-grey-dark font-medium pb-2 border-b-2 mr-8 border-transparent'>
            Select a payment method
          </span>
        </div>
        <hr className='border-grey-lighter' />
      </div>
    </div>
    // </div>
  )
}

function LoginOrRegister () {
  return (
    <div className='yeti-container container mt-6'>
      <div className='yeti-row justify-center align-center row py-8'>
        <Link
          className='yeti-button focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white nord-button--v-contained nord-button--c-blue bg-blue border-blue block text-center'
          to='/login'
        >
          Login
        </Link>
        <div className='mt-3 mx-6'>or</div>
        <Link
          className='yeti-button focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white nord-button--v-contained nord-button--c-blue bg-blue border-blue block text-center'
          to='/register'
        >
          Register
        </Link>
      </div>
      <div className='yeti-row justify-center align-center row py-8'>
        and continue subscription process
      </div>
    </div>
  )
}

function LegalInfo (props) {
  return (
    <div className='legal-info mt-3 yeti-text text-micro leading-normal text-grey font-medium'>
      <hr className='border-grey-lighter mb-3'></hr>
      By submitting purchase form, you agree to our{' '}
      <a
        className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker'
        target='_blank'
        rel='noopener noreferrer'
        href='https://yetivp.com/terms.html'
      >
        terms of service
      </a>{' '}
      and{' '}
      <a
        className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker'
        target='_blank'
        rel='noopener noreferrer'
        href='https://yetivp.com/privacy.html'
      >
        privacy policy
      </a>
      . <br />
      Card payment services are subscription-based and will automatically renew
      for the following period until you cancel.
    </div>
  )
}

function SubOrLogin (props) {
  const {
    paypalClientID,
    selectedPlanID,
    selectedPlan,
    account,
    token,
    plans,
    purchaseMonths,
    setPurchaseMonths,
    showPaddleLoader,
    setShowPaddleLoader
  } = props

  const [buttonText, setButtonText] = useState('Pay with Card')
  const [productInfo, setProductInfo] = useState(null)

  useEffect(() => {
    if (!plans || !selectedPlanID) return null
    let productPaddle
    if (selectedPlanID === plans.custom.id) {
      productPaddle = selectedPlan.providers.paddle[purchaseMonths]
    } else {
      productPaddle = selectedPlan.providers.paddle
    }
    setProductInfo(productPaddle)

    if (!productPaddle) {
      setButtonText('Card Payment Unavailable')
    } else {
      setButtonText('Pay with Card')
    }
  }, [plans, selectedPlanID])
  // useEffect(() => {
  //   if (account && plans) {
  //     const selectedPlan = plans.common.findIndex(plan => plan.id === selectedPlanID) || plans.custom
  //     const product = selectedPlan.providers?.paddle?.id || 22362

  //     Paddle.Checkout.open({
  //       method: 'inline',
  //       product,
  //       email: account.email,
  //       allowQuantity: false,
  //       disableLogout: true,
  //       frameTarget: 'checkout-container', // The className of your checkout <div>
  //       frameInitialHeight: 416,
  //       frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;'    // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
  //     })
  //   }
  // }, [plans, selectedPlanID])
  let priceResult = 0
  if (plans && selectedPlan && purchaseMonths) {
    priceResult =selectedPlan.priceResult
    if (selectedPlanID === plans.custom.id) {
      let savePercent = 0
      for (let i = 0; i < purchaseMonths; i++) {
        savePercent += selectedPlan.monthSave[i]
      }
      priceResult =
        (selectedPlan.priceMonthUSD * purchaseMonths * (100 - savePercent)) / 100

      priceResult = `$${priceResult.toFixed(2)}`
    }
  }

  if (account) {
    const { id, email, paymentInfo } = account

    return (
      <>
        <div className='payment-methods row mt-3'>
          <div className='metamask-wrapper col-12 md:col-12 lg:col-4'>
            
            <button
              className={`button button--third metamask-buttons web3-pay flex items-center justify-center ${
                !productInfo ? 'disabled' : ''
              }`}
              onClick={() => {
                if (!productInfo) {
                  return null
                }
                const product = productInfo.id
                console.log('Paddle productID', product)
                // Paddle.Spinner.show()
                setShowPaddleLoader(true)
                Paddle.Checkout.open({
                  product,
                  email,
                  passthrough: JSON.stringify({ id })
                })
              }}
            >
              <PaddleContent
                showPaddleLoader={showPaddleLoader}
                buttonText={buttonText}
              />
            </button>
            <div className='text-micro font-medium text-grey-dark payment-price'>
              {priceResult}
            </div>
          </div>
          {/* <div className='checkout-container'></div> */}
          <Yeti
            plans={plans}
            selectedPlanID={selectedPlanID}
            selectedPlan={selectedPlan}
            purchaseMonths={purchaseMonths}
            account={account}
            token={token}
          />
        </div>
        <LegalInfo />
      </>
    )
  } else {
    return <LoginOrRegister />
  }
}

function PaddleContent (props) {
  const { showPaddleLoader, buttonText } = props
  if (!showPaddleLoader) return <>{buttonText}</>

  return (
    <>
      Loading payment system
      <div className='loader '>
        <RingLoader
          color='#000000'
          loading
          css={css`
            margin: 0 20px;
          `}
          size={18}
        />
      </div>
    </>
  )
}

export default Home
