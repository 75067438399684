import React, { useEffect, useState } from 'react'
import {
  BSC,
  useEthers,
  useToken,
  useTokenBalance,
  // useEtherBalance,
  // useContractCalls,
  // useGasPrice,
  // useSendTransaction,
  // useContractFunction
} from '@usedapp/core'

// import { BigNumber, formatFixed, parseFixed } from '@ethersproject/bignumber'
import {
  // formatEther,
  formatUnits,
  // parseEther,
  // parseUnits
} from '@ethersproject/units'
// import { Interface } from '@ethersproject/abi'
// import { Contract } from '@ethersproject/contracts'
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

// ethers.utils.isAddress('0xaddress')

// import logo from '../../yeti_icon.svg'
import metamaskLogo from '../assets/metamask-fox.min.svg'
import walletConnectLogo from '../assets/walletconnect.svg'
import bscLogo from '../assets/bsc-icon.webp'

const yetiContractAddress = '0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9'

export function InstallMetaMaskButton (props) {
  const { ethers, error, installed } = props
  const { account } = ethers
  const noProvider = error && error.name === 'NoEthereumProviderError'
  const noMeta = !installed || noProvider

  if (!account && noMeta) {
    return (
      <>
        <a
          className='button button--third metamask-buttons connect-metamask'
          href='https://metamask.io/download.html'
          target='_blank'
          rel='noopener noreferrer'
        >
            INSTALL METAMASK
            <br />
            WHEN RELOAD PAGE
            <img
              alt='MetaMask logo'
              src={metamaskLogo}
              className='connect-metamask-logo'
            />
        </a>
        <button
          className='button button--third metamask-buttons'
          onClick={() => {
            document.location.reload()
          }}
        >
          RELOAD
        </button>
      </>
    )
  }
  return null
}

export function MetaMaskButton (props) {
  const { ethers, installed, setConnectorName } = props
  const { account, activateBrowserWallet, deactivate, error } = ethers
  const noProvider = error && error.name === 'NoEthereumProviderError'
  const noMeta = !installed || noProvider

  if (noMeta || account) {
    return null
  }

  return (
    <button
      className='button button--third metamask-buttons connect-metamask'
      onClick={() => {
        if (account) {
          deactivate()
        } else {
          activateBrowserWallet()
          setConnectorName('InjectedConnector')
        }
      }}
    >
      CONNECT METAMASK
      <WalletLogo logo={metamaskLogo} />
    </button>
  )
}

export function ConnectWalletButton (props) {
  const { ethers, installed, setConnectorName } = props
  const { account, activateBrowserWallet, deactivate, error } = ethers
  const noProvider = error && error.name === 'NoEthereumProviderError'
  const noMeta = !installed || noProvider

  if (noMeta || account) {
    return null
  }

  return (
    <button
      className='button-balance'
      onClick={() => {
        if (account) {
          deactivate()
        } else {
          activateBrowserWallet()
          setConnectorName('InjectedConnector')
        }
      }}
    >
      CONNECT WALLET
      {/* <WalletLogo logo={metamaskLogo} /> */}
    </button>
  )
}

export function DisconnectButton (props) {
  const { ethers, connectorName } = props
  const { account, deactivate } = ethers
  if (!account) return null

  let logo
  switch (connectorName) {
    case 'InjectedConnector':
      logo = metamaskLogo
      break
    case 'WalletConnectConnector':
      logo = walletConnectLogo
      break
    default:
      break
  }

  return (
    <button
      className={`button button--third metamask-buttons ${
        logo ? 'connect-metamask' : ''
      }`}
      onClick={() => {
        deactivate()
      }}
    >
      DISCONNECT WALLET
      <WalletLogo logo={logo} />
    </button>
  )
}

export function WalletLogo ({ logo }) {
  if (!logo) return null

  return <img alt='Wallet logo' src={logo} className='connect-metamask-logo' />
}

export function WalletConnectButton (props) {
  const { ethers, setConnectorName, walletConnect } = props
  const { account, activate, deactivate, error } = ethers
  if (account) {
    return null
  }
  return (
    <button
      className='button button--third metamask-buttons connect-metamask'
      onClick={() => {
        activate(walletConnect, undefined, true).catch(err => {
          console.error('Failed to activate account using Wallet Connect', err)
          walletConnect.walletConnectProvider = undefined
        })
        setConnectorName('WalletConnectConnector')
      }}
    >
      WALLET CONNECT
      <WalletLogo logo={walletConnectLogo} />
    </button>
  )
}

export function WatchYETIButton (props) {
  const { ethers, connectorName } = props
  const { account, chainId, library, error } = ethers

  if (!account || connectorName !== 'InjectedConnector') return null

  return (
    <button
      className='button button--third metamask-buttons'
      onClick={() => {
        addYeti(library)
      }}
    >
      ADD YETI TO METAMASK
      {/* <img alt='YETI logo' src={logo} className='connect-metamask-logo' /> */}
    </button>
  )
}

export function ToBSCButton (props) {
  const { ethers } = props
  const { account, chainId, library } = ethers

  if (account && chainId !== BSC.chainId) {
    return (
      <button
        className='button button--third metamask-buttons connect-metamask'
        onClick={async () => {
          await switchNetwork(library)
        }}
      >
        SWITCH TO BSC MAINNET
        <img alt='BSC logo' src={bscLogo} className='connect-metamask-logo' />
      </button>
    )
  } else {
    return null
  }
}

export function ToBSCHeaderButton (props) {
  const { ethers } = props
  const { account, chainId, library } = ethers

  if (account && chainId !== BSC.chainId) {
    return (
      <button
        className='button-balance'
        onClick={async () => {
          await switchNetwork(library)
        }}
      >
        SWITCH TO BSC MAINNET
        {/* <img alt='BSC logo' src={bscLogo} className='connect-metamask-logo' /> */}
      </button>
    )
  } else {
    return null
  }
}

export function ToggleButton (props) {
  const { ethers, hideButtons, setHideButtons } = props
  const { account, chainId, deactivate } = ethers

  if (account && chainId === BSC.chainId) {
    const shortAccount = `${account.substr(0, 5)}...${account.substr(-4)}`

    return (
      <button
        className={'button-balance'}
        onClick={async () => {
          deactivate()
        }}
      >
        {`${shortAccount}`}
      </button>
    )
  } else {
    return null
  }
}

export function DAppInfo (props) {
  const { ethers } = props
  const { account, chainId } = ethers
  const daiInfo = useToken(yetiContractAddress)
  const yetiBalance = useTokenBalance(yetiContractAddress, account)

  if (!account || (chainId && chainId !== BSC.chainId)) {
    return (
      <div className='white'>
        <pre>{ethers.error && ethers.error.name}</pre>
        {account && <p>Account: {account}</p>}
        {chainId && <p>ChainId: {chainId}</p>}
      </div>
    )
  }

  const shortAccount = `${account.substr(0, 5)}...${account.substr(-4)}`
  const yetiBalanceFormated = yetiBalance ? formatUnits(yetiBalance, 9) : 0 // daiInfo?.decimals ||

  return (
    <>
      <div className='white'>
        <pre>{ethers.error && ethers.error.name}</pre>
        {account && <p>Account: {shortAccount}</p>}
        {yetiBalance && (
          <p>
            Balance: {yetiBalanceFormated} {daiInfo?.symbol} ({daiInfo?.name})
          </p>
        )}
      </div>
    </>
  )
}

export function YETIBalance (props) {
  const ethers = useEthers()
  const { account } = ethers
  const yetiInfo = useToken(yetiContractAddress)
  const yetiBalance = useTokenBalance(yetiContractAddress, account)
  const yetiBalanceFormated = yetiBalance ? formatUnits(yetiBalance, 9) : 0 // daiInfo?.decimals ||

  return (
    <div className='balance'>{+(+yetiBalanceFormated).toFixed(4)} YETI</div>
  )
}

export function HeaderYETIBalance (props) {
  const ethers = useEthers()
  const { account, chainId, library } = ethers
  const yetiInfo = useToken(yetiContractAddress)
  const yetiBalance = useTokenBalance(yetiContractAddress, account)
  const yetiBalanceFormated = yetiBalance ? formatUnits(yetiBalance, 9) : 0 // daiInfo?.decimals ||

  if (!account || (chainId && chainId !== BSC.chainId)) return null

  return (
    <button
      className={'button-balance balance'}
      onClick={async () => {
        addYeti(library)
      }}
    >
      {+(+yetiBalanceFormated).toFixed(4)} YETI
    </button>
  )
}

// **********************************************************

export async function addYeti (library) {
  library.provider
    .request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: yetiContractAddress,
          symbol: 'YETI',
          decimals: 9,
          image: 'https://yeticoin.tech/logo192.png'
        }
      }
    })
    .then(success => {
      if (success) {
        console.log('YETI successfully added to wallet!')
      } else {
        console.log('Something went wrong.')
      }
    })
    .catch(console.error)
}

export async function switchNetwork (library) {
  try {
    await library.provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${BSC.chainId.toString(16)}` }] // chainId must be in hexadecimal numbers
    })
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await addNetwork(library)
      } catch (addError) {
        console.log('addError', addError)
      }
    } else {
      console.log('switchError', switchError)
    }
  }
}

export async function addNetwork (library) {
  await library.provider.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: `0x${BSC.chainId.toString(16)}`,
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
          name: 'Binance Coin',
          symbol: 'BNB',
          decimals: 18
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com']
      }
    ]
  })
}
