import React from 'react'
import SelectDot from './SelectDot'

export function Motivation () {
  return (
    <div className='yeti-container container'>
      <div className='yeti-row row'>
        <div className='yeti-col col'>
          <div className='flex text-left'>
            <p className='yeti-text text-base font-medium leading-normal text-grey-darker'>
              Encrypt your internet connection to protect your data and privacy
            </p>
          </div>
          <ul className='mt-4 md:mt-3'>
            <li className='flex mb-2'>
              <Check />
              <span className='yeti-text text-micro leading-normal text-grey-darker'>
                Strict no logs policy
              </span>
            </li>
            <li className='flex mb-2'>
              <Check />
              <span className='yeti-text text-micro leading-normal text-grey-darker'>
                High-speed connections
              </span>
            </li>
            <li className='flex mb-2'>
              <Check />
              <span className='yeti-text text-micro leading-normal text-grey-darker'>
                Powerful modern encryption
              </span>
            </li>
          </ul>
        </div>
        {/* <div className='yeti-col md:col-auto hidden md:block'>
          <div className='inline-flex items-center'>
            <img
              className='yeti-image'
              src={moneyBackBadge}
              alt='money back badge small'
              // style="width: 46px; height: 55px; min-width: 46px;"
            />
            <div className='font-bold uppercase text-grey-darkest whitespace-no-wrap ml-2 text-small leading-tight ml-2'>
              <div>
                Money-back <br /> 100% guarantee
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export function Check () {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      className='inline-block text-green mr-2'
      focusable='false'
      // style="width: 16px; height: 16px; min-width: 16px;"
    >
      <path
        d='M13 5.496L11.571 4 6.47 9.342 4.43 7.205 3 8.701l3.47 3.632z'
        fill='#151922'
      />
    </svg>
  )
}

export function Plans ({ showBest, plans, selectedPlanID, setSelectedPlanID }) {
  if (plans && selectedPlanID) {
    return (
      <div className='yeti-container container'>
        <div className='mt-3 mb-3 sm:mb-3 md:mb-3 lg:mt-5 lg:mb-3'>
          <div className='yeti-row row'>
            {plans.common.map(plan => (
              <Plan
                showBest={showBest}
                key={`plan_${plan.id}`}
                plan={plan}
                selectedPlanID={selectedPlanID}
                setSelectedPlanID={setSelectedPlanID}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
      Loading awailable subscription plans...
    </div>
  )
}

export function Plan ({ showBest, plan, selectedPlanID, setSelectedPlanID }) {
  const selected = selectedPlanID === plan.id
  return (
    <>
      <div className={'yeti-col col-12 md:col-12 lg:col-4 flex flex-col ' + (showBest && plan.best ? 'pb-3' : 'mb-3')}>
        <button
          type='button'
          className={
            'focus:outline-none flex w-full px-3 py-6 md:px-4 text-grey-darkest rounded-2 border-2 shadow-1 bg-white border-white transition-all duration-250 ease-out  text-left flex-auto ' +
            (selected ? 'border-blue' : '') +
            (showBest && plan.best ? ' rounded-b-none' : '')
          }
          onClick={() => {
            setSelectedPlanID(plan.id)
          }}
        >
          <SelectDot selected={selected} />
          <div className='flex flex-col justify-between self-stretch w-full font-medium'>
            <div>
              <div className='flex flex-wrap justify-between items-center'>
                <div className='text-base lg:text-lead mb-2 mr-2'>
                  {plan.title}
                </div>
                <div>
                  <div
                    className={
                      'px-3 py-1 rounded-3 inline-block text-micro font-medium transition-colors duration-250 ease-out mb-2 ' +
                      (selected
                        ? 'bg-blue text-white'
                        : 'bg-grey-lighter text-grey-darker')
                    }
                  >
                    Save {plan.save}%
                  </div>
                </div>
              </div>
              <div data-testid='ProductCard__price' className='mb-2'>
                <span data-testid='ProductPrice' className='price'>
                  <span className='yeti-text text-base leading-normal text-h4 leading-loose'>
                    {plan.price}
                  </span>
                </span>
                <span className='text-h4 lg:text-base'>/mo</span>
              </div>
            </div>
            <p className='text-micro font-medium text-grey-dark'>
              {plan.priceFull ? <del>{plan.priceFull}</del> : null}
              <span className='text-blue'>{plan.priceResult}</span>{' '}
              {plan.periodDesc}
              <span className='text-small' data-testid='IntroductorySymbol'>
                *
              </span>
            </p>
          </div>
        </button>
      </div>

      {showBest && plan.best ? <BestValue selected={selected} plan={plan} setSelectedPlanID={setSelectedPlanID} /> : null}
    </>
  )
}

function BestValue ({ selected, plan, setSelectedPlanID }) {
  return (
    <div className='yeti-col col-12 md:col-12 lg:col-4 mb-3 lg:order-last lg:mb-6 best-value-sub'>
      <div className='ProductCardBanner relative transition-opacity duration-250 ease-out rounded-b-2 bg-blue-lightest'>
        <div
          className='yeti-responsive-background transition-all duration-250 ease-in-out bg-transparent absolute w-full h-full top-0 left-0 rounded-b-2'
          // style='background-image: none; background-repeat: no-repeat; background-size: cover;'
        />
        <div
          className={
            'ProductCardBanner__hideTopShadow absolute w-full right-0 top-0 border-2 border-transparent border-t-0 border-b-0 bg-white transition-border duration-250 ease-out' +
            (selected ? ' border-blue' : '')
          }
        />
        <button
          className='ProductCardBannerButton relative w-full h-full px-4 lg:px-6 pb-3 pt-2 focus:outline-none text-left'
          type='button'
          onClick={() => {
            setSelectedPlanID(plan.id)
          }}
        >
          <div className='font-medium mb-3 mt-1 text-h5 best-value'>
            Best value
          </div>
          <div className='font-medium text-small text-grey-darkest'>
            You save the most by choosing this plan.
          </div>
          <div
            className={
              'absolute w-full h-full top-0 left-0 border-2 border-transparent border-t-0 transition-all duration-250 ease-out shadow-1 rounded-b-2' +
              (selected ? ' border-blue' : '')
            }
          />
        </button>
      </div>
    </div>
  )
}
