import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './styles/index.css'
import { DAppProvider } from '@usedapp/core'
import App from './App'
import reportWebVitals from './reportWebVitals'

const config = {
  readOnlyChainId: 56,
  readOnlyUrls: {
    1: 'https://mainnet.infura.io/v3/10dc06d1ed0446a7aabb9dc86df0983d',
    56: 'https://bsc-dataseed.binance.org/'
  }
}

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <DAppProvider config={config}>
        <App />
      </DAppProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
