import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { css } from '@emotion/react'
import { RingLoader } from 'react-spinners'
import { getTokenInfo, isSubscribedCheck } from '../utils'

export default function Processing (props) {
  const { token, setAccount } = props
  const navigate = useNavigate()
  const [checkInterval, setCheckInterval] = useState(null)

  const checkToken = async () => {
    if (!token) return
    const accountInfo = await getTokenInfo(token)
    console.log('accountInfo', accountInfo)
    if (accountInfo.success) {
      const isSubscribed = isSubscribedCheck(accountInfo)
      if (isSubscribed) {
        clearInterval(checkInterval)
        setAccount(accountInfo)
        navigate(`/activated?token=${token}`)
      }
    }
  }

  useEffect(() => {
    // checkToken()
    const interval = setInterval(checkToken, 3000)
    setCheckInterval(interval)
    return () => {
      clearInterval(interval)
      clearInterval(checkInterval)
    }
  }, [])

  return (
    <div className='yeti-container container text-black flex-grow flex-shrink-0 px-10'>
      <div className='Card2 smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex-col'>
        <form className='min-w-0 items-center justify-center'>
          <fieldset className='min-w-0 items-center justify-center flex-grow flex-col'>
            <h2 className='yeti-text text-h4 leading-normal font-medium tracking-tight mb-6 text-center'>
              Processing
            </h2>
            <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
              We are waiting for confirmation from the payment system.
              <br />
              This can usually take about a minute.
            </div>
          </fieldset>
        </form>
        <div className='loader flex w-full flex-grow items-center justify-center mt-6 mb-6'>
          <RingLoader
            color='#000000'
            loading
            css={css`
              margin: 0 auto;
            `}
            size={64}
          />
        </div>
      </div>
    </div>
  )
}
