import React, { useEffect, useState } from 'react'
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import localforage from 'localforage'

import Modal from 'react-modal'
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
import {
  Login,
  Register,
  Reset,
  UpdatePassword,
  OTCSend,
  OTCLogin
} from './pages/Auth'

import Home from './pages/Home'
import Processing from './pages/Processing'
import SubscriptionActivated from './pages/Activated'
import Header from './components/Header'
import Footer from './components/Footer'
import CancelSub from './components/CancelSub'
import { getTokenInfo, getSubPlans, isSubscribedCheck } from './utils'

import './styles/App.css'
import './styles/asd.css'
import './styles/purchase.css'
// import plans from './subPlans'

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'none',
    borderRadius: '0',
    padding: '10px'
  }
}

Modal.setAppElement('#root')
const modalLocations = ['/processing', '/activated']

const Paddle = window.Paddle

function App () {
  const navigate = useNavigate()
  const [plans, setPlans] = useState(null)
  const [selectedPlanID, setSelectedPlanID] = useState(null)
  const [paypalClientID, setPaypalClientID] = useState(null)
  const [token, setToken] = useState(null)
  const [account, setAccount] = useState(null)
  const [initEmail, setInitEmail] = useState('')
  const [resetCode, setResetCode] = useState('')
  const [loginCode, setLoginCode] = useState('')
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const isSubscribed = isSubscribedCheck(account)
  const showPurchaseInfo = !isSubscribed && location.pathname === '/'
  const [modalShow, setModalShow] = useState(false)
  const [showPaddleLoader, setShowPaddleLoader] = useState(false)

  useEffect(() => {
    const loc = modalLocations.includes(location.pathname)
    const cancel = isSubscribed && location.pathname === '/cancel'
    if (loc || cancel) {
      setModalShow(true)
    } else {
      setModalShow(false)
    }
  }, [isSubscribed, location])

  useEffect(() => {
    // Paddle.Environment.set('sandbox')
    // console.log(Paddle.Status.libraryVersion)
    Paddle.Setup({
      vendor: 139630,// sandbox - 4434,
      eventCallback: function (data) {
        // The data.event will specify the event type
        console.log(data.event, data.eventData)

        if (data.event === 'Checkout.Loaded') {
          // Paddle.Spinner.hide()
          setShowPaddleLoader(false)
        } if (data.event === 'Checkout.Complete') {
          navigate('/processing')
        } else if (data.event === 'Checkout.Close') {
          setShowPaddleLoader(false)
        }
      }
    })
  }, [])

  function afterOpenModal () {
    // references are now sync'd and can be accessed.
  }

  useEffect(() => {
    if (!token) {
      let t = searchParams.get('token')
      console.log('token', t)
      // setToken(t)

      const run = async () => {
        if (t) {
          try {
            setToken(t)
            await localforage.setItem('token', t)
          } catch (err) {
            console.log('save token error', err)
          }
        } else {
          try {
            t = await localforage.getItem('token')
            if (t) {
              setToken(t)
            } else {
              if (location.pathname === '/') {
                navigate('/login')
              }
            }
          } catch (err) {
            console.log('get token error', err)
            navigate('/login')
          }
        }
      }
      run()

    }
  }, [location])

  // useEffect(() => {
  //   if (!token && location.pathname === '/') {
  //     navigate('/login')
  //   }
  // }, [token, location])

  useEffect(() => {
    const t = searchParams.get('email')
    console.log('initEmail', t)
    setInitEmail(t)
  }, [])

  useEffect(() => {
    const t = searchParams.get('resetCode')
    console.log('resetCode', t)
    setResetCode(t)
  }, [])

  useEffect(() => {
    const t = searchParams.get('loginCode')
    console.log('loginCode', t)
    setLoginCode(t)
  }, [])

  useEffect(() => {
    if (!token) return null
    if (account) return null
    const run = async () => {
      const accountInfo = await getTokenInfo(token)
      console.log('accountInfo', accountInfo)
      if (accountInfo.success) {
        // const { id, email, paymentInfo } = accountInfo
        setAccount(accountInfo)
      }
    }
    run()
  }, [token, account, location])

  useEffect(() => {
    const run = async () => {
      const plansRes = await getSubPlans()
      console.log('plansRes', plansRes)
      if (plansRes) {
        setSelectedPlanID(plansRes.plans.common[0].id)
        // setPaypalClientID(plansRes.paypalClientID)
        setPlans(plansRes.plans)
      }
    }
    run()
  }, [])

  return (
    <div className='App flex flex-col min-h-screen bg-grey-lightest'>
      <Header account={account} setAccount={setAccount} setToken={setToken} />
      <Routes>
        <Route path='/login' element={<Login setToken={setToken} />} />
        <Route path='/register' element={<Register setToken={setToken} />} />
        <Route path='/reset' element={<Reset initEmail={initEmail} />} />
        <Route
          path='/resetcode'
          element={<UpdatePassword setToken={setToken} resetCode={resetCode} />}
        />
        <Route path='/codelogin' element={<OTCSend initEmail={initEmail} />} />
        {/* <Route
          path='/processing'
          element={<Processing token={token} setAccount={setAccount} />}
        />
        <Route
          path='/activated'
          element={<SubscriptionActivated account={account} />}
        /> */}
        <Route
          path='/otccode'
          element={
            <OTCLogin
              setToken={setToken}
              initEmail={initEmail}
              loginCode={loginCode}
            />
          }
        />
        <Route
          path='*'
          element={
            <Home
              paypalClientID={paypalClientID}
              selectedPlanID={selectedPlanID}
              setSelectedPlanID={setSelectedPlanID}
              account={account}
              token={token}
              plans={plans}
              showPaddleLoader={showPaddleLoader}
              setShowPaddleLoader={setShowPaddleLoader}
            />
          }
        />
      </Routes>
      <Modal
        isOpen={modalShow}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setModalShow(false)}
        style={modalStyles}
        contentLabel='Modal'
      >
        <button
          className='modalClose focus:outline-none'
          onClick={() => setModalShow(false)}
        >
          <div className='burger burger--active'>
            <div className='burger__line' />
            <div className='burger__line' />
          </div>
        </button>
        <div className='text-center flex flex-col max-w-md'>
          <Routes location={location}>
            <Route
              path='/processing'
              element={<Processing token={token} setAccount={setAccount} />}
            />
            <Route
              path='/activated'
              element={<SubscriptionActivated account={account} />}
            />
            <Route
              path='/cancel'
              element={
                <CancelSub
                  token={token}
                  account={account}
                  setAccount={setAccount}
                  onConfirm={() => setModalShow(false)}
                  onCancel={() => setModalShow(false)}
                />
              }
            />
          </Routes>
        </div>
      </Modal>
      <Footer showPurchaseInfo={showPurchaseInfo} />
    </div>
  )
}

export default App
