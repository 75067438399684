import React from 'react'

export default function Footer({ showPurchaseInfo }) {
  return (
    <footer className='z-1 flex-shrink-0 smd:shadow-1 bg-white'>
      <div className='yeti-container container'>
        <div className='yeti-row row py-4 md:py-6'>
          {showPurchaseInfo ? <PricingInfo /> : null}

          <div className='yeti-col col-12 lg:col-8 flex flex-wrap items-center justify-start'>
            <span className='yeti-text text-micro leading-normal text-grey font-medium order-last md:order-first mt-3 md:mt-0 w-full md:w-auto text-left'>
              Copyright © 2022 YETI APPS LTD
            </span>
            <span className='yeti-text text-micro leading-normal font-medium mr-6 md:ml-6 py-1'>
              <a
                className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker'
                href='mailto:contact@yetivp.com'
              >
                contact@yetivp.com
              </a>
            </span>

            <span className='yeti-text text-micro leading-normal font-medium mr-6 md:ml-6 py-1'>
              <a
                className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker'
                target='_blank'
                rel='noopener noreferrer'
                href='https://yetivp.com/terms.html'
              >
                Terms of Service
              </a>
            </span>

            <span className='yeti-text text-micro leading-normal font-medium mr-6 md:ml-6 py-1'>
              <a
                className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker'
                target='_blank'
                rel='noopener noreferrer'
                href='https://yetivp.com/privacy.html'
              >
                Privacy Policy
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

function PricingInfo () {
  return (
    <div className='yeti-col col-12 mt-5 md:mt-6 lg:mt-0 mb-0 lg:mb-3'>
      <p className='yeti-text text-micro leading-normal text-grey font-medium w-full text-left'>
        <span className='yeti-text text-small leading-normal'>*</span> The price
        is valid for the first term of your subscription. After the first term,
        your subscription will be automatically renewed for an additional
        period. The price is subject to change, but we will send you a
        notification email prior to changes.
        {/* <a
        className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker'
        target='_blank'
        rel='noopener noreferrer'
        href='#'
      >
        Learn more
      </a> */}
      </p>
    </div>
  )
}
